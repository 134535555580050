<template>
    <div class="inform-account-restored">
        <public-modal-title>
            {{ $t(!success ? "prerecording" : "congratulations") }}
        </public-modal-title>
        <public-modal-description class="mt-10" v-if="success">
            {{ $t("prerecording_success") }}
        </public-modal-description>
        <flex-container class="mt-20" style="height: 100px" align="center" justify="center">
            <SpinLoader v-if="!success"/>
            <MountainsInformImg v-else/>
        </flex-container>
        <PublicModalButtonNext v-if="success" class="mt-30" @click="gate" :text="$t('next')"/>
        <div class="error" v-if="message">
            {{ message }}
        </div>
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer"
import SpinLoader from "@components/Loaders/SpinLoader"
import PublicModalButtonNext from "../button/PublicModalButtonNext"
import PublicModalDescription from "../typography/PublicModalDescription"
import PublicModalTitle from "../typography/PublicModalTitle"

import * as axi from "axios"
import MountainsInformImg from "./img/MountainsInformImg"

export default {
    components: {
        PublicModalDescription,
        PublicModalButtonNext,
        MountainsInformImg,
        SpinLoader,
        FlexContainer,
        PublicModalTitle
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                productId: "0"
            })
        }
    },
    name: "InformProductPrerecording",
    data() {
        return {
            message: "",
            loading: true
        }
    },
    async created() {
        try {
            this.loading = true
            const {token, app_current_locale} = window
            const axios = axi.create({
                baseURL: "/api/v1/",
                withCredentials: true,
                headers: {
                    common: {
                        Authorization: `Bearer ${token}`,
                        "X-Language": app_current_locale
                    }
                }
            })
            await axios.post(`/products/${this.data.productId}/users/pre-subscribe`)
            this.loading = false
        } catch (e) {
            if (e.response.data.message) {
                this.message = e.response.data.message
            } else {
                this.message = "Error"
            }
        }
    },
    computed: {
        success() {
            return !this.loading && !this.message
        }
    },
    methods: {
        gate() {
            window.location = "/profile/gate"
        }
    }
}
</script>
