<template>
    <div class="authorization-flow authorization-flow-static">
        <div v-if="step">
            <public-modal-title class="mb-30">
                {{ step.title }}
            </public-modal-title>
            <form @submit.prevent.stop>
                <AuthorizationDynamicFields
                    @keyup.native.enter="submit"
                    :errors="errors"
                    :fields="step.fields"
                    v-if="step.fields.length && isQuickAuth"
                />
                <DefaultInput
                    v-else-if="step.fields.length"
                    @keyup.native.enter="submit"
                    :label="step.fields[0].input_title"
                    :use-focus="true"
                    :errors="errors.username"
                    :required="true"
                    v-model="formData.username"
                    autocomplete="email"
                    ref="emailInput"
                    type="email"
                    name="email"
                />
                <div v-if="displayMessage()" class="error">
                    {{ message }}
                </div>
                <div :class="$screenWidth > 480 ? 'mb-30' : 'mb-20'"></div>
                <PublicModalButtonNext
                    v-if="step.fields.length"
                    @click="submit"
                    :disabled="isDisabled()"
                    :text="$t('next')"
                />
                <div class="socials-block" v-if="step.socials.length">
                    <div v-if="step.fields.length" class="socials-block-or">
                        <div class="socials-block-delimiter"></div>
                        <span>{{ $t("or") }}</span>
                        <div class="socials-block-delimiter"></div>
                    </div>
                    <flex-container justify="center">
                        <div
                            @click="handleSocial(social)"
                            :key="index"
                            v-for="(social, index) in step.socials.filter(social => social !== 'telegram')"
                            class="socials-block-social"
                        >
                            <div class="socials-block-social__image">
                                <img :src="`/build/img/social/${social}.svg`" class="" alt="" />
                            </div>
                            {{ social }}
                        </div>
                    </flex-container>
                </div>
            </form>
            <public-modal-description class="mt-16">
                <ExtendedRenderHtml :html="`<pre class='text-view'> ${step.policy} </pre>`" />
            </public-modal-description>
        </div>
    </div>
</template>

<script>
import ExtendedRenderHtml from "@components/ExtendedRender/ExtendedRenderHtml.vue"
import PublicModalTitle from "../typography/PublicModalTitle"
import PublicModalDescription from "../typography/PublicModalDescription"
import PublicModalButtonNext from "../button/PublicModalButtonNext"
import AuthRepository from "../../shared/repository/auth-repository"
import { FLOW_TYPES } from "./data/flow-types"
import DefaultInput from "@components/Forms/DefaultInput"
import FlexContainer from "@components/Containers/FlexContainer"
import axios from "@libs/axios"
import AuthorizationDynamicFields from "@public-modal/components/flow/fields/AuthorizationDynamicFields.vue"
import * as axi from "axios"
import { EventBus } from "~events"
import { DEFAULT_AUTH_KEY } from "@public-modal/shared/keys/default-auth-key"

const NEXT_STEPS = {
    CODE: "code",
    PASSWORD: "password",
    REGISTRATION: "registration",
    CHECK_ACCESS: "check_access"
}

const baseURL = "/api/v1/"

const urlParams = new URLSearchParams(location.search)

export default {
    name: "AuthorizationStaticFlow",
    props: {
        data: {
            type: Object,
            default: () => ({
                username: "",
                settings: null
            })
        }
    },
    components: {
        ExtendedRenderHtml,
        AuthorizationDynamicFields,
        FlexContainer,
        DefaultInput,
        PublicModalTitle,
        PublicModalDescription,
        PublicModalButtonNext
    },
    data() {
        return {
            formData: {
                username: ""
            },
            errors: {},
            message: "",
            settings: null,
            socialHandlerCalled: false
        }
    },
    async created() {
        EventBus.$on(`${DEFAULT_AUTH_KEY}_OPEN`, () => {
            setTimeout(() => {
                if (this.$refs.emailInput) {
                    this.$refs.emailInput.focusOnInput()
                }
            }, 200)
        })

        const email = urlParams.get("email")

        if (email) {
            this.data.username = email
        }

        this.formData = { ...this.formData, username: this.data.username }

        if (this.data.settings) {
            this.settings = this.data.settings
            return
        }

        this.$emit("loading", true)

        try {
            const result = await AuthRepository.settings()
            if (result) {
                this.settings = result
                if (result && result.step_1 && result.step_1.fields.length === 1 && result.step_1.fields[0].value) {
                    this.formData.username = result.step_1.fields[0].value
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.$emit("loading", false)
        }
    },
    computed: {
        isQuickAuth() {
            return this.settings.register_type_id === 2
        },
        step() {
            if (!this.settings) {
                return null
            } else {
                return this.settings.step_1
            }
        }
    },
    methods: {
        isRequiredFieldsFilled(fields) {
            return !fields.filter(f => f.is_required && !f.value).length
        },
        isDisabled() {
            return false

            // eslint-disable-next-line no-unreachable
            if (!this.isQuickAuth) {
                return !this.formData.username
            }

            return !this.isRequiredFieldsFilled(this.step.fields)
        },
        handleSocial(driver) {
            this.$emit("loading", true)

            window.addEventListener("message", this.loginSocialHandler)

            const isSmall = window.innerWidth <= 960

            const width = isSmall ? window.innerWidth : window.innerWidth - 460
            const height = isSmall ? window.innerHeight : window.innerHeight - 100
            const top = isSmall ? 0 : 100
            const left = isSmall ? 0 : 230

            if (driver === "facebook") {
                window.dataLayer &&
                    window.dataLayer.push({ event: !window.app_subdomain ? "auth-fb-btn" : "school-auth-fb-btn" })
            }
            if (driver === "google") {
                window.dataLayer &&
                    window.dataLayer.push({ event: !window.app_subdomain ? "auth-go-btn" : "school-auth-go-btn" })
            }
            const w = window.open(
                `/auth/${driver}?settings_id=${this.settings.id}`,
                "_blank",
                `width=${width},height=${height},top=${top},left=${left}`
            )

            const timer = setInterval(() => {
                if ((w && w.closed) || !w) {
                    this.$emit("loading", false)
                    clearInterval(timer)
                }
            }, 1000)
        },
        async loginSocialHandler($event) {
            if (this.socialHandlerCalled) {
                return
            }

            this.socialHandlerCalled = true

            setTimeout(() => {
                this.socialHandlerCalled = false
            }, 1000)

            if (!$event.data || typeof $event.data !== "string") {
                return
            }

            const result = JSON.parse($event.data)
            if (!result || result.event !== "LOGGED_BY_SOCIAL") {
                return
            }

            const data = result.detail.data
            const { redirect_url, bearer, success, next_step, user_data } = data

            if (window.is_custom_domain) {
                this.$emit("loading", true)
                const axios = axi.create({
                    baseURL,
                    headers: {
                        common: {
                            Authorization: `Bearer ${bearer.token}`,
                            "X-Language": window.app_current_locale
                        }
                    }
                })

                try {
                    await axios.get("/auth/web-by-api")
                } catch (e) {
                    console.log(e)
                } finally {
                    this.$emit("loading", false)
                }
            }

            if (success && bearer && redirect_url) {
                if (bearer.token) {
                    localStorage.setItem("token", bearer.token)
                    if (typeof AuthRepository.loginAction === "function") {
                        AuthRepository.loginAction({ token: bearer.token })
                    } else {
                        window.location = redirect_url
                    }
                } else {
                    window.location = redirect_url
                }
            }

            if (next_step === "registration_socials" && user_data) {
                this.$emit("loading", true)
                const url = `${this.step.step_2_fields_endpoint}?user_social_account_id=${user_data.id}`
                try {
                    const result = await axios.get(url)
                    if (result) {
                        const { fields, registration_endpoint } = result.data.data
                        const { username } = this.formData

                        this.$emit("set", {
                            ...FLOW_TYPES.DYNAMIC_FLOW,
                            previous: {
                                ...FLOW_TYPES.STATIC_AUTHORIZATION,
                                data: {
                                    username,
                                    settings: this.settings
                                }
                            },
                            data: {
                                step: {
                                    ...this.settings.step_2,
                                    registration_endpoint,
                                    fields: [
                                        ...fields,
                                        {
                                            is_hidden: true,
                                            name: "user_social_account_id",
                                            value: user_data.id
                                        }
                                    ]
                                }
                            }
                        })
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    this.$emit("loading", false)
                }
            }
        },
        async submit() {
            this.$emit("loading", true)
            try {
                const { username } = this.formData

                if (window.auth_check_access) {
                    AuthRepository.loginAction = () => {
                        let url = new URL(window.location.href)
                        url.searchParams.append("check_access", "1")
                        window.location = url.toString()
                    }
                }

                let result = null

                if (!this.isQuickAuth) {
                    result = await AuthRepository.login({
                        username,
                        url: "auth/login"
                    })
                } else {
                    result = await AuthRepository.quick({
                        fields: this.mapFieldsToPayload(),
                        url: this.step.registration_endpoint
                    })
                }

                if (result) {
                    const { channel, mask, hash, next_step } = result.data

                    const flow = (function () {
                        switch (next_step) {
                            case NEXT_STEPS.CODE: {
                                return FLOW_TYPES.AUTHORIZATION_BY_CODE
                            }
                            case NEXT_STEPS.PASSWORD: {
                                return FLOW_TYPES.AUTHORIZATION_PASSWORD
                            }
                            case NEXT_STEPS.CHECK_ACCESS: {
                                let url = new URL(window.location.href)
                                url.searchParams.append("check_access", "1")
                                window.location = url.toString()
                                return null
                            }
                            case NEXT_STEPS.REGISTRATION: {
                                window.dataLayer &&
                                    window.dataLayer.push({
                                        event: !window.app_subdomain ? "auth-btn1" : "school-auth-btn1"
                                    })

                                return FLOW_TYPES.DYNAMIC_FLOW
                            }
                            default: {
                                return null
                            }
                        }
                    })(next_step)

                    if (flow) {
                        if (next_step === NEXT_STEPS.REGISTRATION) {
                            this.$emit("set", {
                                ...flow,
                                previous: {
                                    ...FLOW_TYPES.STATIC_AUTHORIZATION,
                                    data: {
                                        username,
                                        settings: this.settings
                                    }
                                },
                                data: {
                                    step: {
                                        ...this.settings.step_2,
                                        fields: [
                                            ...this.settings.step_2.fields,
                                            ...this.step.fields.map(f => ({ ...f, is_hidden: true, value: username })),
                                            {
                                                is_hidden: true,
                                                name: "hash",
                                                value: hash || ""
                                            }
                                        ]
                                    }
                                }
                            })
                        } else {
                            this.$emit("set", {
                                ...flow,
                                previous: {
                                    ...FLOW_TYPES.STATIC_AUTHORIZATION,
                                    data: {
                                        username,
                                        settings: this.settings
                                    }
                                },
                                data: {
                                    mask,
                                    channel,
                                    username,
                                    hash
                                }
                            })
                        }
                    }
                }
            } catch (e) {
                const { message, errors, error_type } = e.response.data
                this.message = message || error_type
                if (errors) {
                    this.errors = errors
                } else {
                    this.errors = {}
                }
            } finally {
                this.$emit("loading", false)
            }
        },
        mapFieldsToPayload() {
            const formData = new FormData()
            this.step.fields.forEach(field => {
                if (
                    field.type === "timezone" ||
                    field.type === "currency" ||
                    field.type === "dropdown" ||
                    field.type === "country"
                ) {
                    if (field.value) {
                        formData.append(field.name, field.value.id || "")
                    }
                } else {
                    formData.append(field.name, field.value || "")
                }
            })

            return formData
        },
        displayMessage() {
            return this.message && !Object.keys(this.errors).length
        }
    },
    destroyed() {
        window.removeEventListener("message", this.loginSocialHandler)
    },
    watch: {
        step: {
            deep: true,
            handler() {
                this.$forceUpdate()
            }
        }
    }
}
</script>
<style lang="sass">
.socials-block
    margin-top: 24px
    .socials-block-social
        font-size: 16px
        text-transform: capitalize
        line-height: 21px
        font-weight: 500
        color: #B5C1D2
        min-width: 68px
        display: flex
        flex-direction: column
        align-items: center
        transition: .2s
        cursor: pointer
        &__image
            width: 58px
            height: 58px
            display: flex
            align-items: center
            justify-content: center
            margin-bottom: 8px
            border-radius: 20px
            border: 1px solid #ECF1F5
            transition: .2s
        &:not(&:last-child)
            margin-right: 32px
        &:hover
            color: #3965FF
            .socials-block-social__image
                border-color: #3965FF

.socials-block-or
    display: flex
    align-items: center
    margin-bottom: 24px
    .socials-block-delimiter
        height: 1px
        width: 100%
        background: #3965FF
    span
        color: #B5C1D2
        font-family: 'Ubuntu', serif
        font-size: 16px
        line-height: 21px
        display: block
        font-weight: 500
        margin: 0 10px
</style>
