import * as axi from "axios"

const axios = axi.create({
    baseURL: "/api/v1/",
    withCredentials: true,
    headers: {
        "X-Language": window.app_current_locale
    }
})

const modifyFields = fields => {
    return fields.map(field => {
        return {
            ...field,
            text: field.title,
            value: ""
        }
    })
}

const authSettingsUrl = window.auth_settings_url || "public/auth/settings"

export default class AuthRepository {
    static loginAction = null
    static TariffId = null
    static multiplier = null
    static WebinarTariffId = null
    static async settings() {
        const result = await axios.get(authSettingsUrl)
        result.data.data.step_2.fields = modifyFields(result.data.data.step_2.fields)
        return result.data.data
    }

    static async register({ payload, url }) {
        if (this.TariffId) {
            payload.append("tariff_id", this.TariffId)
        }
        if (this.multiplier) {
            payload.append("multiplier", this.multiplier)
        }
        if (this.WebinarTariffId) {
            payload.append("webinar_tariff_id", this.WebinarTariffId)
        }
        return axios.post(url, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    static async login({ username, url = "auth/login" }) {
        return axios.post(url, {
            username
        })
    }

    static async quick({ fields, url = "auth/login" }) {
        return axios.post(url, fields, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    static async loginConfirm({ username, code, password, hash }) {
        let payload = {
            username,
            code,
            hash,
            password
        }
        if (this.TariffId) {
            payload = { ...payload, tariff_id: this.TariffId }
        }
        if (this.multiplier) {
            payload = { ...payload, multiplier: this.multiplier }
        }
        if (this.WebinarTariffId) {
            payload = { ...payload, webinar_tariff_id: this.WebinarTariffId }
        }
        return axios.post("auth/login/confirm", payload)
    }

    static async restore({ username }) {
        return axios.post("auth/restore", {
            username
        })
    }

    static async restoreConfirm({ username, code, hash }) {
        return axios.post("auth/restore/confirm", {
            username,
            code,
            hash
        })
    }

    static async setPassword({ password, password_confirmation, reset_hash }) {
        return axios.post("auth/restore/set-password", {
            password,
            password_confirmation,
            reset_hash
        })
    }
}
