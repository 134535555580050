<template>
    <div class="inform-account-restored">
        <public-modal-title class="mb-20">
            {{ $t("account_restored") }}
        </public-modal-title>
        <flex-container justify="center">
            <MountainsInformImg />
        </flex-container>
        <PublicModalButtonNext class="mt-30" @click="gate" :text="$t('enter')" />
    </div>
</template>

<script>
import PublicModalTitle from "../typography/PublicModalTitle"
import MountainsInformImg from "./img/MountainsInformImg"
import PublicModalButtonNext from "../button/PublicModalButtonNext"
import FlexContainer from "@components/Containers/FlexContainer"

export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                redirect_url: ""
            })
        }
    },
    name: "InformAccountRestored",
    components: { FlexContainer, PublicModalButtonNext, MountainsInformImg, PublicModalTitle },
    methods: {
        gate() {
            window.location = this.data.redirect_url
        }
    }
}
</script>

<style scoped>

</style>
