<template>
    <div class="auth-by-password">
        <form autocomplete="on" @keydown.enter="submit()" @submit.stop.prevent>
            <input
                class="hidden-field"
                :value="data.username"
                name="username"
                autocomplete="username email"
                type="text"
            />
            <public-modal-title class="mb-30">
                {{ $t("authorization_platform") }}
            </public-modal-title>

            <DefaultPasswordInput
                :use-focus="true"
                :errors="errors.password"
                v-model="formData.password"
                :required="true"
                name="current-password"
                :label="$t('enter_password')"
            />
            <div v-if="displayMessage()" class="error">
                {{ message }}
            </div>
            <div class="mb-30 mt-10">
                <public-modal-description large align="left">
                    {{ $t("forgot_password") }} <a @click.prevent="restore">{{ $t("restore_access") }} →</a>
                </public-modal-description>
            </div>
            <public-modal-buttons-container>
                <PublicModalButtonPrev :text="$t('back')" @click="$emit('set', previous)" />
                <PublicModalButtonNext :text="$t('next')" @click="submit" :disabled="!formData.password" />
            </public-modal-buttons-container>
        </form>
    </div>
</template>

<script>
import PublicModalTitle from "../typography/PublicModalTitle"
import DefaultPasswordInput from "@components/Forms/DefaultPasswordInput"
import PublicModalButtonsContainer from "../button/PublicModalButtonsContainer"
import PublicModalButtonPrev from "../button/PublicModalButtonPrev"
import PublicModalButtonNext from "../button/PublicModalButtonNext"
import AuthRepository from "../../shared/repository/auth-repository"
import PublicModalDescription from "../typography/PublicModalDescription"
import { FLOW_TYPES } from "./data/flow-types"

export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                mask: "",
                channel: "",
                username: "",
                hash: ""
            })
        },
        previous: {
            type: Object,
            default: () => {}
        }
    },
    name: "AuthorizationByPasswordFlow",
    components: {
        PublicModalDescription,
        PublicModalButtonNext,
        PublicModalButtonPrev,
        PublicModalButtonsContainer,
        DefaultPasswordInput,
        PublicModalTitle
    },
    data() {
        return {
            formData: {
                password: ""
            },
            errors: {
                password: []
            },
            message: ""
        }
    },
    methods: {
        restore() {
            this.$emit("loading", true)
            setTimeout(() => {
                this.$emit("set", {
                    ...FLOW_TYPES.RESTORE_ACCOUNT,
                    previous: {
                        ...FLOW_TYPES.AUTHORIZATION_PASSWORD,
                        data: this.formData,
                        previous: this.previous
                    },
                    data: {
                        username: this.data.username
                    }
                })
                this.$emit("loading", false)
            }, 500)
        },
        async submit() {
            this.$emit("loading", true)
            try {
                const { password } = this.formData
                const { username, hash } = this.data
                const result = await AuthRepository.loginConfirm({ username, password, hash })
                if (result) {
                    const { bearer, redirect_url, channel, hash, mask, next_step } = result.data

                    if (next_step && next_step === "code") {
                        this.$emit("set", {
                            ...FLOW_TYPES.AUTHORIZATION_BY_CODE,
                            previous: {
                                ...FLOW_TYPES.AUTHORIZATION_PASSWORD,
                                data: this.data,
                                previous: this.previous
                            },
                            data: {
                                mask,
                                channel,
                                username,
                                hash
                            }
                        })
                    } else {
                        window.token = bearer.token

                        localStorage.setItem("token", bearer.token)

                        if (typeof AuthRepository.loginAction === "function") {
                            AuthRepository.loginAction({ token: bearer.token })
                        } else {
                            window.location = redirect_url
                        }
                    }
                }
            } catch (e) {
                console.log(e)
                const { message, errors } = e.response.data
                this.message = message
                if (errors) {
                    this.errors = errors
                } else {
                    this.errors = {
                        password: []
                    }
                }
            } finally {
                this.$emit("loading", false)
            }
        },
        displayMessage() {
            return this.message && !this.errors.password.length
        }
    }
}
</script>

<style scoped>

</style>
