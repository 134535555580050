import InformProductPrerecording from "../../inform/InformProductPrerecording"
import AuthorizationStaticFlow from "../AuthorizationStaticFlow"
import AuthorizationByCodeFlow from "../AuthorizationByCodeFlow"
import AuthorizationByPasswordFlow from "../AuthorizationByPasswordFlow"
import AuthorizationRestoreFlow from "../AuthorizationRestoreFlow"
import AuthorizationDynamicFlow from "../AuthorizationDynamicFlow"
import InformAccountRestored from "../../inform/InformAccountRestored"

export const FLOW_VIEWS = {
    STATIC_AUTHORIZATION: AuthorizationStaticFlow,
    AUTHORIZATION_BY_CODE: AuthorizationByCodeFlow,
    AUTHORIZATION_BY_PASSWORD: AuthorizationByPasswordFlow,
    RESTORE_ACCOUNT: AuthorizationRestoreFlow,
    DYNAMIC_FLOW: AuthorizationDynamicFlow,
    ACCOUNT_RESTORED: InformAccountRestored,
    PRODUCT_PRERECORDING: InformProductPrerecording
}
