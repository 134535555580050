export const FLOW_TYPES = {
    STATIC_AUTHORIZATION: {
        key: "STATIC_AUTHORIZATION",
        data: {
            username: "",
            settings: null
        }
    },
    AUTHORIZATION_BY_CODE: {
        key: "AUTHORIZATION_BY_CODE",
        data: {
            mask: "",
            channel: "",
            username: "",
            hash: "",
            is_restore: false
        }
    },
    AUTHORIZATION_PASSWORD: {
        key: "AUTHORIZATION_BY_PASSWORD",
        data: {
            mask: "",
            channel: "",
            username: "",
            hash: ""
        }
    },
    RESTORE_ACCOUNT: {
        key: "RESTORE_ACCOUNT",
        data: {
            username: "",
            reset_hash: ""
        }
    },
    ACCOUNT_RESTORED: {
        key: "ACCOUNT_RESTORED",
        data: {
            redirect_url: ""
        }
    },
    PROJECT_CREATION: {
        key: "PROJECT_CREATION",
        data: {}
    },
    PRODUCT_PRERECORDING: {
        key: "PRODUCT_PRERECORDING",
        data: {
            productId: null
        }
    },
    DYNAMIC_FLOW: {
        key: "DYNAMIC_FLOW",
        data: {
            step: null
        }
    }
}
