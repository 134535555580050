<template>
    <div class="auth-by-code">
        <public-modal-title class="mb-6">
            {{ data.is_restore ? $t("restore_account") : $t("authorization_platform") }}
        </public-modal-title>
        <public-modal-description large class="mb-30">
            {{ $t("on_your") }} <template v-if="data.channel === 'email'"> {{ $t("email") }} </template>
            <a>{{ data.mask }}</a> {{ $t("code_has_been_sent") }}
        </public-modal-description>
        <form @submit.prevent.stop>
            <DefaultInput
                :errors="errors.code"
                v-model="formData.code"
                :use-focus="true"
                type="number"
                name="one-time-code"
                autocomplete="code"
                :label="$t('enter_code')"
                class="mb-6"
            />
            <input hidden :value="data.username" name="email" autocomplete="username email" type="text" />
            <div v-if="displayMessage()" class="error mb-6">
                {{ message }}
            </div>
            <public-modal-description large align="left">
                {{ $t("code_is_valid") }} <b>10 {{ $t("minutes") }}</b>
            </public-modal-description>
            <public-modal-description large align="left" v-if="timer !== 0">
                {{ $t("request_new_code") }} <strong>{{ timer }} {{ $t("sec") }}</strong>
            </public-modal-description>
            <public-modal-description large align="left" v-else>
                <a @click.prevent="send" class="link">
                    {{ $t("send_again") }}
                </a>
            </public-modal-description>
            <div class="mb-30"></div>
            <public-modal-buttons-container>
                <PublicModalButtonPrev :text="$t('back')" @click="$emit('set', previous)" />
                <PublicModalButtonNext type="submit" :text="$t('next')" @click="submit" :disabled="!formData.code" />
            </public-modal-buttons-container>
        </form>
    </div>
</template>

<script>
import PublicModalTitle from "../typography/PublicModalTitle"
import PublicModalDescription from "../typography/PublicModalDescription"
import DefaultInput from "@components/Forms/DefaultInput"
import PublicModalButtonNext from "../button/PublicModalButtonNext"
import PublicModalButtonsContainer from "../button/PublicModalButtonsContainer"
import PublicModalButtonPrev from "../button/PublicModalButtonPrev"
import AuthRepository from "../../shared/repository/auth-repository"
import { FLOW_TYPES } from "./data/flow-types"
import { v1 as uuid } from "uuid"
import TimeoutWorker from "@workers/timeout-worker?worker"

export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                mask: "",
                channel: "",
                username: "",
                hash: "",
                is_restore: false
            })
        },
        previous: {
            type: Object,
            default: () => {}
        }
    },
    name: "AuthorizationByCodeFlow",
    components: {
        PublicModalButtonPrev,
        PublicModalButtonsContainer,
        PublicModalButtonNext,
        DefaultInput,
        PublicModalDescription,
        PublicModalTitle
    },
    data() {
        return {
            formData: {
                code: ""
            },
            errors: {
                code: []
            },
            message: "",
            timer: 0,
            interval: null,
            worker: null
        }
    },

    created() {
        try {
            this.worker = new TimeoutWorker()
        } catch (e) {
            this.worker = {}
            console.log(e)
        }

        this.timer = 60
        this.$emit("loading", true)
        setTimeout(() => {
            this.$emit("loading", false)
        }, 500)
    },
    methods: {
        async submit() {
            this.$emit("loading", true)
            try {
                const { code } = this.formData
                const { username, hash, is_restore } = this.data
                if (is_restore) {
                    const result = await AuthRepository.restoreConfirm({ username, code, hash })
                    if (result) {
                        const { reset_hash } = result.data
                        this.$emit("set", {
                            ...FLOW_TYPES.RESTORE_ACCOUNT,
                            previous: {
                                ...FLOW_TYPES.AUTHORIZATION_BY_CODE,
                                data: this.formData,
                                previous: this.previous
                            },
                            data: {
                                reset_hash,
                                username
                            }
                        })
                    }
                } else {
                    const result = await AuthRepository.loginConfirm({ username, code, hash })
                    if (result) {
                        const { bearer, redirect_url } = result.data
                        localStorage.setItem("token", bearer.token)
                        window.token = bearer.token
                        if (typeof AuthRepository.loginAction === "function") {
                            AuthRepository.loginAction({ token: bearer.token })
                        } else {
                            window.location = redirect_url
                        }
                    }
                }
            } catch (e) {
                const { message, errors } = e.response.data
                this.message = message
                if (errors) {
                    this.errors = errors
                } else {
                    this.errors = {
                        code: []
                    }
                }
            } finally {
                this.$emit("loading", false)
            }
        },
        async send() {
            const { username, is_restore } = this.data
            this.$emit("loading", true)
            if (is_restore) {
                const result = await AuthRepository.restore({ username })
                if (result) {
                    this.$set(this.data, "hash", result.data.hash)
                }
            } else {
                const result = await AuthRepository.login({ username })
                if (result) {
                    this.$set(this.data, "hash", result.data.hash)
                }
            }
            this.timer = 60
            this.$emit("loading", false)
        },
        displayMessage() {
            return this.message && !this.errors.code.length
        }
    },
    watch: {
        timer(val) {
            if (val && !this.interval) {
                const id = uuid()

                this.worker.postMessage({
                    name: "setInterval",
                    id,
                    time: 1000
                })

                this.worker.onmessage = () => {
                    this.timer -= 1
                }

                this.interval = id
            } else if (!val) {
                this.worker.postMessage({
                    name: "clearInterval",
                    id: this.interval
                })
                this.interval = null
            }
        }
    }
}
</script>

<style scoped>

</style>
