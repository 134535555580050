<template>
    <div class="dynamic-flow">
        <public-modal-title v-if="step.title">
            {{ step.title }}
        </public-modal-title>
        <public-modal-description large class="mt-6" v-if="step.sub_title">
            <span v-html="step.sub_title"></span>
        </public-modal-description>
        <form @keypress.enter.stop.prevent id="signup" autocomplete="on" @submit.prevent.stop>
            <input
                v-if="getLastField().type === 'email'"
                class="hidden-field"
                :value="getLastField().value"
                name="email"
                autocomplete="username email"
                type="text"
            />
            <AuthorizationDynamicFields
                :errors="errors"
                :fields="step.fields"
                class="mt-30"
                v-if="step.fields.length"
            />
            <div v-if="displayMessage()" class="error">
                {{ message }}
            </div>
            <public-modal-buttons-container class="mt-30">
                <!--   !isRequiredFieldsFilled(step.fields)-->
                <PublicModalButtonPrev v-if="previous" :text="$t('back')" @click="$emit('set', previous)" />
                <PublicModalButtonNext :text="$t('next')" @click="submit" :disabled="false" />
            </public-modal-buttons-container>
        </form>
        <public-modal-description class="mt-16" v-if="step.policy">
            <ExtendedRenderHtml :html="`<pre class='text-view'> ${step.policy} </pre>`" />
        </public-modal-description>
    </div>
</template>

<script>
import ExtendedRenderHtml from "@components/ExtendedRender/ExtendedRenderHtml.vue"
import PublicModalTitle from "../typography/PublicModalTitle"
import AuthorizationDynamicFields from "./fields/AuthorizationDynamicFields"
import PublicModalButtonsContainer from "../button/PublicModalButtonsContainer"
import PublicModalButtonPrev from "../button/PublicModalButtonPrev"
import PublicModalButtonNext from "../button/PublicModalButtonNext"
import PublicModalDescription from "../typography/PublicModalDescription"
import AuthMixin from "../../shared/mixins/auth-mixin"
import AuthRepository from "../../shared/repository/auth-repository"

const urlParams = new URLSearchParams(location.search)

export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                step: null
            })
        },
        previous: {
            type: Object,
            default: () => {}
        },
        additionalFields: {
            type: Object,
            default: () => null
        }
    },
    name: "AuthorizationDynamicFlow",
    mixins: [AuthMixin],
    components: {
        ExtendedRenderHtml,
        PublicModalDescription,
        PublicModalButtonNext,
        PublicModalButtonPrev,
        PublicModalButtonsContainer,
        AuthorizationDynamicFields,
        PublicModalTitle
    },
    data() {
        return {
            errors: {},
            message: ""
        }
    },
    created() {
        const tel = urlParams.get("tel")

        if (tel) {
            if (this.step && this.step.fields) {
                for (const field of this.step.fields) {
                    if (field.type === "phone") {
                        field.value = tel
                        this.$forceUpdate()
                    }
                }
            }
        }
    },
    computed: {
        step() {
            return this.data.step
        }
    },
    methods: {
        getLastField() {
            return this.step.fields[this.step.fields.length - 1]
        },
        async submit() {
            this.$emit("loading", true)
            try {
                const result = await AuthRepository.register({
                    url: this.step.registration_endpoint,
                    payload: this.mapFieldsToPayload()
                })
                if (result) {
                    window.dataLayer
                    && window.dataLayer.push({event: !window.app_subdomain ? "auth-btn2" : "school-auth-btn2"})

                    window.fbq && window.fbq("track", "CompleteRegistration")

                    const { bearer, redirect_url } = result.data
                    localStorage.setItem("token", bearer.token)
                    window.token = bearer.token
                    if (typeof AuthRepository.loginAction === "function") {
                        AuthRepository.loginAction({ token: bearer.token })
                    } else {
                        window.location = redirect_url
                    }
                }
            } catch (e) {
                const { message, errors } = e.response.data
                this.message = message
                if (errors) {
                    this.errors = errors
                } else {
                    this.errors = {}
                }
            } finally {
                this.$emit("loading", false)
            }
        },
        mapFieldsToPayload() {
            const formData = new FormData()
            this.step.fields.forEach(field => {
                if (
                    field.type === "timezone" ||
                    field.type === "currency" ||
                    field.type === "dropdown" ||
                    field.type === "country"
                ) {
                    if (field.value) {
                        formData.append(field.name, field.value.id)
                    }
                } else {
                    formData.append(field.name, field.value)
                }
            })

            if (this.additionalFields) {
                for (const [key, value] of Object.entries(this.additionalFields)) {
                    formData.append(key, value)
                }
            }

            return formData
        },
        displayMessage() {
            return this.message && !Object.keys(this.errors).length
        }
    }
}
</script>

<style scoped>

</style>
