<template>
    <div class="auth-restore">
        <form @submit.stop.prevent @keydown.enter="submit">
            <public-modal-title>
                {{ $t("restore_account") }}
            </public-modal-title>
            <template v-if="!data.reset_hash">
                <DefaultInput
                    :errors="errors.username"
                    v-model="formData.username"
                    type="email"
                    name="email"
                    :use-focus="true"
                    autocomplete="email"
                    :required="true"
                    :label="$t('enter_username')"
                    class="mt-30"
                />
                <div v-if="displayMessage()" class="error">
                    {{ message }}
                </div>
                <div class="mb-30"></div>
                <public-modal-buttons-container>
                    <PublicModalButtonPrev :text="$t('back')" @click="$emit('set', previous)" />
                    <PublicModalButtonNext :text="$t('next')" @click="submit" :disabled="!formData.username" />
                </public-modal-buttons-container>
            </template>
            <template v-else>
                <input
                    class="hidden-field"
                    :value="data.username"
                    name="email"
                    autocomplete="username email"
                    type="text"
                />
                <public-modal-description large class="mt-6">
                    {{ $t("create_new_password") }}
                </public-modal-description>
                <DefaultPasswordInput
                    class="mt-30"
                    autocomplete="new-password"
                    :required="true"
                    v-model="formData.password"
                    :errors="errors.password"
                    :label="$t('enter_password')"
                />
                <DefaultPasswordInput
                    class="mt-16"
                    name="password_confirmation"
                    autocomplete="new-password"
                    :required="true"
                    v-model="formData.password_confirmation"
                    :errors="errors.password_confirmation"
                    :label="$t('repeat_password')"
                />
                <div v-if="displayMessage()" class="error">
                    {{ message }}
                </div>
                <public-modal-buttons-container class="mt-30">
                    <PublicModalButtonPrev :text="$t('back')" @click="$emit('set', previous)" />
                    <PublicModalButtonNext :text="$t('next')" @click="submit" :disabled="!formData.password" />
                </public-modal-buttons-container>
            </template>
        </form>
    </div>
</template>

<script>
import PublicModalTitle from "../typography/PublicModalTitle"
import DefaultInput from "@components/Forms/DefaultInput"
import PublicModalButtonsContainer from "../button/PublicModalButtonsContainer"
import PublicModalButtonPrev from "../button/PublicModalButtonPrev"
import PublicModalButtonNext from "../button/PublicModalButtonNext"
import AuthRepository from "../../shared/repository/auth-repository"
import { FLOW_TYPES } from "./data/flow-types"
import PublicModalDescription from "../typography/PublicModalDescription"
import DefaultPasswordInput from "@components/Forms/DefaultPasswordInput"

export default {
    components: {
        DefaultPasswordInput,
        PublicModalDescription,
        PublicModalButtonNext,
        PublicModalButtonPrev,
        PublicModalButtonsContainer,
        DefaultInput,
        PublicModalTitle
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                username: "",
                reset_hash: ""
            })
        },
        previous: {
            type: Object,
            default: () => {}
        }
    },
    name: "AuthorizationRestoreFlow",
    data() {
        return {
            formData: {
                username: this.data.username,
                password: "",
                password_confirmation: ""
            },
            errors: {
                username: [],
                password: [],
                password_confirmation: []
            },
            message: ""
        }
    },
    methods: {
        async submit() {
            this.$emit("loading", true)
            try {
                if (!this.data.reset_hash) {
                    const { username } = this.formData
                    const result = await AuthRepository.restore({ username })
                    if (result) {
                        const { channel, mask, hash } = result.data
                        this.$emit("set", {
                            ...FLOW_TYPES.AUTHORIZATION_BY_CODE,
                            previous: {
                                ...FLOW_TYPES.RESTORE_ACCOUNT,
                                data: this.formData,
                                previous: this.previous
                            },
                            data: {
                                mask,
                                channel,
                                username,
                                hash,
                                is_restore: true
                            }
                        })
                    }
                } else {
                    const { password, password_confirmation } = this.formData
                    const { reset_hash } = this.data
                    const result = await AuthRepository.setPassword({ password, password_confirmation, reset_hash })

                    if (result) {
                        const { bearer, redirect_url } = result.data.success
                        localStorage.setItem("token", bearer.token)
                        window.token = bearer.token
                        if (typeof AuthRepository.loginAction === "function") {
                            AuthRepository.loginAction({ token: bearer.token })
                        } else {
                            this.$emit("set", {
                                ...FLOW_TYPES.ACCOUNT_RESTORED,
                                data: {
                                    redirect_url
                                }
                            })
                        }
                    }
                }
            } catch (e) {
                const { message, errors } = e.response.data
                this.message = message
                if (errors) {
                    for (const key of Object.keys(this.errors)) {
                        if (errors.hasOwnProperty(key)) {
                            this.$set(this.errors, key, errors[key])
                            this.$forceUpdate()
                        }
                    }
                } else {
                    this.errors = {
                        username: [],
                        password: [],
                        password_confirmation: []
                    }
                }
            } finally {
                this.$emit("loading", false)
            }
        },
        displayMessage() {
            return (
                this.message &&
                !this.errors.username.length &&
                !this.errors.password.length &&
                !this.errors.password_confirmation.length
            )
        }
    }
}
</script>

<style scoped>

</style>
